import axios from "axios";
import { wersjaMax, grupa_max,grupa_udzial_akt, mieszkancyMax, grupy_all_max_akt } from '../functions/grupy';
import { getTablicaCzynsze2, getTablicaPrzelewy2, getSaldo } from '../functions/tablice';
import { sortByTxt } from "../functions/utilities";

// if (process.env.NODE_ENV === 'production') {
//   axios.defaults.baseURL = 'https://system.bonadministrator.pl/';
// } else {
//   axios.defaults.baseURL = 'http://localhost:3001/';
// }

if (window.location.hostname === 'system.bonadministrator.pl') {
  axios.defaults.baseURL = 'https://system.bonadministrator.pl/';
} else if (window.location.hostname === 'test.bonadministrator.pl') {
  axios.defaults.baseURL = 'https://test.bonadministrator.pl/';
} else {
  axios.defaults.baseURL = 'http://localhost:3001/';
}

export const setUserPasswordSend = data => ({
  type: 'SET_USER_PASSWORD_SEND',
  payload: data
})
export const fetchUserPasswordSend = () => {
  return (dispatch) => {
    axios.get("api/password/send/history")
    .then(response => {
      dispatch(setUserPasswordSend(response.data));
    })
    .catch(error => console.error('There was an error!', error));
  }
}

export const setUser = data => ({
  type: 'SET_USER',
  payload: data
});
export const fetchUser = i => {
  return (dispatch) => {
    dispatch(setUser(i));
  }
}

export const setCred = data => ({
  type: 'SET_CRED',
  payload: data
})
export const fetchCred = (cred) => {
  return (dispatch) => {
      dispatch(setCred(cred));
  }
}

export const setLoginUser = data => ({
  type: 'SET_LOGIN_USER',
  payload: data
})
export const fetchLoginUser = login => {
  return (dispatch) => {
    dispatch(setLoginUser(login));
  }
}

export const setWspolnoty = (data) => ({
  type: 'SET_WSPOLNOTY',
  payload: data,
});
export const fetchWspolnoty = () => {
  return (dispatch) => {
    axios.get("api/lista_wspolnot")
      .then(response => {
        dispatch(setWspolnoty(response.data));
      })
      .catch(error => console.error('There was an error!', error));
  };
};

export const setUsers = data => ({
  type: 'SET_USERS',
  payload: data
})
export const fetchUsers = () => {
  return (dispatch) => {
    axios.get("api/users")
      .then(response => {
        if (Array.isArray(response.data))
        {
          dispatch(setUsers(response.data));
        }
        else {
          console.log("błąd");
          dispatch(setUsers([{"błąd": "błąd"}]));
        }
          
      })
      .catch(error => console.error('There was an error!', error));
  };
}

export const setMieszkancy = data => ({
  type: 'SET_MIESZKANCY',
  payload: data
});
export const fetchMieszkancy = () => {
  return (dispatch) => {
    axios.get("api/mieszkancy")
      .then(response => {
        if (Array.isArray(response.data))
        {
          dispatch(setMieszkancy(sortByTxt(mieszkancyMax(response.data),"imie_nazwisko")));
        }
        else {
          console.log("błąd");
          dispatch(setMieszkancy([{"błąd": "błąd"}]));
        }
          
      })
      .catch(error => console.error('There was an error!', error));
  };
}

export const setMieszkancyAll = data => ({
  type: 'SET_MIESZKANCY_ALL',
  payload: data
});
export const fetchMieszkancyAll = () => {
  return (dispatch) => {
    axios.get("api/mieszkancy")
      .then(response => {
        dispatch(setMieszkancyAll(sortByTxt(response.data,"imie_nazwisko")));
      })
      .catch(error => console.error('There was an error!', error));
  };
}

export const setLokale = data => ({
  type: 'SET_LOKALE',
  payload: data
});
export const fetchLokale = () => {
  return (dispatch) => {
    axios.get("api/lokale/all")
      .then(response => {
        dispatch(setLokale(response.data));
      })
      .catch(error => console.error('There was an error!', error));
  };
}

export const setUlice = data => ({
  type: 'SET_ULICE',
  payload: data
});
export const fetchUlice = () => {

  return (dispatch) => {
    axios.get("api/ulice/all")
      .then(response => {
        dispatch(setUlice(sortByTxt(response.data,"nazwa")));
      })
      .catch(error => console.error('There was an error!', error));
  };
}

export const setOdczyty = data => ({
  type: 'SET_ODCZYTY',
  payload: data
});
export const fetchOdczyty = () => {
  return (dispatch) => {
    axios.get('/api/odczyty/')
      .then(response => {
        dispatch(setOdczyty(response.data));
      })
      .catch(error => console.log('There was an error!', error))
  }
}

export const setPlanowaneZuzycie = data => ({
  type: 'SET_PLANOWANE_ZUZYCIE',
  payload: data
})
export const fetchPlanowaneZuzycie = () => {

  return (dispatch) => {
    axios.get("api/planowane_zuzycie")
      .then(response => {
        dispatch(setPlanowaneZuzycie(response.data));
      })
      .catch(error => console.error('There was an error!', error));
  };
}

export const setLogi = data => ({
  type: 'SET_LOGI',
  payload: data
});
export const fetchLogi = () => {
  return (dispatch) => {
    axios.get("api/logi")
      .then(response => {
        dispatch(setLogi(response.data));
      })
      .catch(error => console.error('There was an error!', error));
  };
}

export const setPrzelewy = data => ({
  type: 'SET_PRZELEWY',
  payload: data
});
export const fetchPrzelewy = () => {
  return (dispatch) => {
    axios.get("api/przelewy")
      .then(response => {
        dispatch(setPrzelewy(response.data));
      })
      .catch(error => console.error('There was an error!', error));
  };
}

export const setStawki = data => ({
  type: 'SET_STAWKI',
  payload: data
});
export const fetchStawki = () => {
  return (dispatch) => {
    axios.get("api/stawki_wspolnot_arch")
      .then(response => {
        dispatch(setStawki(response.data));
      })
      .catch(error => console.error('There was an error!', error));
  };
}

export const setStawkiInd = data => ({
  type: 'SET_STAWKI_IND',
  payload: data
})
export const fetchStawkiInd = () => {
  return (dispatch) => {
    axios.get("api/stawki_ind")
      .then(response => {
        dispatch(setStawkiInd(response.data));
      })
      .catch(error => console.error('There was an error!', error));
  };
}

export const setStawkiJednorazowe = data => ({
  type: 'SET_STAWKI_JEDNORAZOWE',
  payload: data
});
export const fetchStawkiJednorazowe = () => {
  return (dispatch) => {
    axios.get("api/wspolnoty_stawki_jednorazowe")
      .then(response => {
        dispatch(setStawkiJednorazowe(response.data));
      })
      .catch(error => console.error('There was an error!', error));
  };
}

export const setStawkiJednorazoweInd = data => ({
  type: 'SET_STAWKI_JEDNORAZOWE_IND',
  payload: data
});
export const fetchStawkiJednorazoweInd = () => {
  return (dispatch) => {
    axios.get("api/wspolnoty_stawki_jednorazowe_ind")
      .then(response => {
        dispatch(setStawkiJednorazoweInd(response.data));
      })
      .catch(error => console.error('There was an error!', error));
  };
}


export const setGrupy = data => ({
  type: 'SET_GRUPY',
  payload: data
});
export const fetchGrupy = () => {
  return (dispatch) => {
    axios.get("api/grupy/")
      .then(response => {
        dispatch(setGrupy(grupa_max(response.data)));
      })
      .catch(error => console.error('There was an error!', error));
  };
}

export const setGrupyAll = data => ({
  type: 'SET_GRUPY_ALL',
  payload: data
});
export const fetchGrupyAll = () => {
  return (dispatch) => {
    axios.get('api/grupy/all')
      .then(response => {
        dispatch(setGrupyAll(response.data));
      })
      .catch(error => console.error('There was an error!', error));
  }
}

export const setWersje = data => ({
  type: 'SET_WERSJE',
  payload: data
});
export const fetchWersje = (grupy) => {
  return (dispatch) => {
    if (grupy && grupy.length > 0) {
      const obliczoneWersje = wersjaMax(grupy);
      dispatch(setWersje(obliczoneWersje));
    } else {
      // console.error('Brak danych grupy do obliczenia wersji');
    }
  };

};

export const setNoty = data => ({
  type: 'SET_NOTY',
  payload: data
});
export const fetchNoty = () => {
  return (dispatch) => {
    axios.get("api/noty")
      .then(response => {
        dispatch(setNoty(response.data));
      })
      .catch(error => console.error('There was an error!', error));
  };
}

export const setGrupyAkt = data => ({
  type: 'SET_GRUPY_AKT',
  payload: data
});
export const fetchGrupyAkt = (grupy, wersje) => {
  return (dispatch) => {
    if (grupy && wersje) {
      const temp = grupy_all_max_akt(grupy,wersje);
      dispatch(setGrupyAkt(temp));
    }
    else {
      // console.log('error');
    }
  }
}

export const setUdzialyKonta = data => ({
  type: 'SET_UDZIALY_KONTA',
  payload: data
});
export const fetchUdzialyKonta = () => {
  return (dispatch) => {
    axios.get("api/grupy_konta_udzialy/")
      .then(response => { 
        dispatch(setUdzialyKonta(response.data));
      })
      .catch(error => console.error('There was an error!', error));
  };
}

export const setUdzialyKontaAkt = data => ({
  type: 'SET_UDZIALY_KONTA_AKT',
  payload: data
});
export const fetchUdzialyKontaAkt = () => {
  return (dispatch) => {
    axios.get("api/grupy_konta_udzialy/")
      .then(response => { 
        dispatch(setUdzialyKontaAkt(grupa_udzial_akt(response.data)));
      })
      .catch(error => console.error('There was an error!', error));
  };
}

export const setUdzialyKontaAll = data => ({
  type: 'SET_UDZIALY_KONTA_ALL',
  payload: data
});
export const fetchUdzialyKontaAll = () => {
  return (dispatch) => {
    axios.get("api/grupy_konta_udzialy/all")
      .then(response => { 
        dispatch(setUdzialyKontaAll(response.data));
      })
      .catch(error => console.error('There was an error!', error));
  };
}

export const setTablicaCzynsze = data => ({
  type: 'SET_TABLICA_CZYNSZE',
  payload: data
}); 
export const fetchTablicaCzynsze = ( wspolnoty, mieszkancy, grupy, grupyUniq, udzialyKonta, udzialyKontaUniq, lokale, stawki, wodaZaliczki /*, zaliczki */ ) => {
  return (dispatch)=>{
    if (wspolnoty && mieszkancy && grupy && grupyUniq && udzialyKonta && udzialyKontaUniq && lokale && stawki && wodaZaliczki) {
      const temp = getTablicaCzynsze2(wspolnoty, mieszkancy, grupy, grupyUniq, udzialyKonta, udzialyKontaUniq, lokale, stawki, wodaZaliczki /*, zaliczki */);
      dispatch(setTablicaCzynsze(temp));
    }
    else {
      // console.log('error');
    }
  }
}

export const setTablicaPrzelewy = data => ({
  type: 'SET_TABLICA_PRZELEWY',
  payload: data
});
export const fetchTablicaPrzelewy = ( wspolnoty, udzialyKontaUniq, przelewy ) => {
  return (dispatch) => {
    if (wspolnoty && udzialyKontaUniq && przelewy) {
      const temp = getTablicaPrzelewy2(wspolnoty, udzialyKontaUniq, przelewy);
      dispatch(setTablicaPrzelewy(temp));
    }
    else {
      // console.log('error');
    }
  }
}

export const setSaldo = data => ({
  type: 'SET_SALDO',
  payload: data
});
export const fetchSaldo = ( tablicaPrzelewy, tablicaCzynsze ) => {
  return (dispatch) => {
    // dispatch(fetchDataStart());
    if (tablicaPrzelewy && tablicaCzynsze) {
      const temp = getSaldo(tablicaPrzelewy, tablicaCzynsze);
      dispatch(setSaldo(temp));
    }
    else {
      // console.log('error')
    }
  }
}

