import React, { useEffect, useState, useRef } from 'react';
import { obliczDatyNoty } from '../../functions/dataFormat.js';

import MieszkancyNotaButtons from './NotaButtons.js';

import NotaA4 from './NotaA4.js';

const NotaModal = (props) => {

    const { showPrint, mieszkaniec, wspolnota, lokale, nota, udzialGrupaNota, setLoaderOn, tablicaCzynsze } = props;

  
    const [tablicaCzynsze_g, setTablicaCzynsze_g] = useState(null);

    useEffect( () => {
        const tablicaCzynsze_w = tablicaCzynsze?.find( t => t.id_wspolnoty == wspolnota?.id);
        const tablicaCzynsze_u = tablicaCzynsze_w?.grupy?.find( t => t.id_user == mieszkaniec?.id_user );

        setTablicaCzynsze_g( tablicaCzynsze_u?.grupy_udzial_msc?.find( g => g?.grupa_numer == udzialGrupaNota?.grupa_numer )  );
        
    },[tablicaCzynsze, mieszkaniec, wspolnota, udzialGrupaNota]);

    const datyNoty = obliczDatyNoty();

    useEffect( () => {
        if ( mieszkaniec && wspolnota && lokale && nota && udzialGrupaNota && tablicaCzynsze_g )
            setLoaderOn(false) 
    },[ mieszkaniec, wspolnota, lokale, nota, udzialGrupaNota, tablicaCzynsze_g ])

    const componentRef = useRef();
    
    const handleModalClick = (event) => event.stopPropagation();

    return (
        <div className="mieszkaniecWydruk" onClick={showPrint}>
            <div className="modal" onClick={handleModalClick}>
                
                <NotaA4
                    componentRef={componentRef} 
                    wspolnota={wspolnota} 
                    mieszkaniec={mieszkaniec}
                    tablicaCzynsze={tablicaCzynsze}
                    udzialGrupaNota={udzialGrupaNota}
                    lokale={lokale}
                    datyNoty={datyNoty}
                />
                <MieszkancyNotaButtons
                    showPrint={showPrint}
                    componentRef={componentRef}
                    email={mieszkaniec?.email}
                    datyNoty={datyNoty}
                />

            </div>



        </div>
    )
}

export default NotaModal;