import React, { useRef, useState } from 'react';
import axios from 'axios';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import NotaA4 from './NotaA4';
import { obliczDatyNoty } from '../../functions/dataFormat';
import { fetchNoty } from '../../Redux/Actions';
import { delay } from '../../functions/utilities';
import {html2pdf} from 'html2pdf.js';
// import { PdfDocument } from '@ironsoftware/ironpdf';

const NotyMasowa = ( props ) => {

  const { tablicaObiektow, setWysylkaStan, wspolnota, tablicaCzynsze, lokale, setLoaderOn, activeDock, setActiveDock } = props;
  
  const divRefs = useRef([]);
  const [showAll, setShowAll] = useState(false);

  const addToHistory = async (obj) => {
    try {
      // Wysłanie PDF na backend
      const response = await axios.post('/api/noty/dodaj', obj);

      if (response.data.success) {
        fetchNoty();
      } else {
        alert('Wystąpił problem z wysyłaniem danych.');
      }
    } catch (error) {
      console.error('Błąd podczas wysyłania danych.', error);
    }
  }

  const [docksList, setDockList] = useState([]);




  const handleGeneratePDF = async () => {

    

    for (let i = 0; i < divRefs.current.length; i ++) {
      const { ref, refNr } = divRefs.current[i];
      const htmlContent = ref.outerHTML;

      const obiekt = tablicaObiektow?.find( t => t?.nota == refNr);
      const msc = obliczDatyNoty()?.miesiacNaliczenia;
      const fileName = `Nota obciążeniowa - ${ obiekt?.mieszkaniec?.imie_nazwisko } - ${obiekt?.nota} - ${ msc }.pdf`;

      
      try {
        const response = await axios.post('/api/generate-pdf', { html: htmlContent, fileName });
        console.log(response.data.message); // Sprawdź odpowiedź z serwera
      } catch (error) {
        console.error('Błąd podczas wysyłania HTML:', error);
      }

    }

    // const element = contentRef.current;
    

  };

  const handleGeneratePDF33 = async() => {

    for (let i = 0; i< divRefs.current.length; i++) {

      const { ref, refNr } = divRefs.current[i];

      const obiekt = tablicaObiektow?.find( t => t?.nota == refNr);
      const msc = obliczDatyNoty()?.miesiacNaliczenia;
      const fileName = `Nota obciążeniowa - ${ obiekt?.mieszkaniec?.imie_nazwisko } - ${obiekt?.nota} - ${ msc }.pdf`;
      console.log(`#header_${obiekt.nota}`)
      const element = document.querySelector(`#header_${obiekt.nota}`)

      html2pdf(element)

    }

      



  }

  // const handleGeneratePDF3 = async () => {
  //   for (let i = 0; i < divRefs.current.length; i++) {

  //     const { ref, refNr } = divRefs.current[i];

      
  //     const obiekt = tablicaObiektow?.find( t => t?.nota == refNr);
  //     const msc = obliczDatyNoty()?.miesiacNaliczenia;
  //     const fileName = `Nota obciążeniowa - ${ obiekt?.mieszkaniec?.imie_nazwisko } - ${obiekt?.nota} - ${ msc }.pdf`;



  //     const convertHTMLToPDF = async (ref) => {
  //       try {
  //           const pdf = await PdfDocument.fromHtml(ref);
  //           await pdf.saveAs(fileName);
  //           alert('PDF generated successfully!');
  //       } catch (error) {
  //           console.error('Error generating PDF:', error);
  //       }
  //     };

  //     convertHTMLToPDF(ref);

  //   }
  // }

  const handleGeneratePDF2 = async () => {
    for (let i = 0; i < divRefs.current.length; i++) {
      
      const { ref, refNr } = divRefs.current[i];

      

      const htmlContent = ref.outerHTML;

      const obiekt = tablicaObiektow?.find( t => t?.nota == refNr);
      const msc = obliczDatyNoty()?.miesiacNaliczenia;
      const fileName = `Nota obciążeniowa - ${ obiekt?.mieszkaniec?.imie_nazwisko } - ${obiekt?.nota} - ${ msc }`;

      // setActiveDock(obiekt.nota);
      setShowAll(true);

      const doc = new jsPDF({
        format: 'a4',
        unit: 'mm',
      });
      doc.setFont('Inter-Regular', 'normal');

      doc.html(ref, {
        async callback(doc) {
          await doc.save(fileName);
        },
      });


      // setWysylkaStan( 
      //   prevState => ({
      //     ...prevState,   
      //     [`stan_${obiekt?.nota}`]: "Generuję pdf..."        
      //   })
      // )
      // const dataToSend = {
      //   html: htmlContent,
      //   fileName,
      //   email: obiekt.mieszkaniec.email,
      //   miesiac: msc
      // }

      // try {
      //   const response = await axios.post('/api/generate-pdf', dataToSend);

      //   if (response.data.success) {
      //     setWysylkaStan( 
      //       prevState => ({
      //         ...prevState,  
      //         [`stan_${obiekt?.nota}`]: "Plik pdf zapisany na serwerze."      
      //       })
      //     );
          
      //     ref.style.display = "none"
      //   } 
      // } catch (error) {
      //   console.log("error: ", error)
      //   setWysylkaStan( 
      //     prevState => ({
      //       ...prevState,   
      //       [`stan_${obiekt?.nota}`]: "Wystąpił błąd."      
      //     })
      //   );
      // }

    }
    setActiveDock(0);
    setShowAll(false);
  };

  const generateDocks = () => {
    return new Promise((resolve, reject) =>{
      const wygenerowanyKodHTML = tablicaObiektow?.map((obj, index) => {
        setWysylkaStan( 
          prevState => ({
            ...prevState,   // Zachowanie poprzedniego stanu
            [`stan_${obj?.nota}`]: "Przygotouję dane..."        // Dodanie nowego klucza 'wiek' z wartością 20
          })
        )
        return (
          <div           
            id={`header_${obj.nota}`}
            ref={(el) => 
              (divRefs.current[index] = {ref: el, refNr: obj.nota})
            }
            key={index}
            // style={ activeDock == obj.nota || showAll ? {display: "block"} : {display: "none"}}
            // style={{display: "none"}}
            className={`previewDock n_${obj.nota}`}
          >
            <NotaA4 
                
                componentRef={null}   
                wspolnota={wspolnota} 
                mieszkaniec={obj.mieszkaniec}
                tablicaCzynsze={tablicaCzynsze}
                udzialGrupaNota={obj.udzialyKontaAkt_u}
                lokale={lokale}
                datyNoty={obliczDatyNoty()}
                setWysylkaStan={setWysylkaStan}
                nota={obj.nota}
                notaIndex={index}
                setLoaderOn={setLoaderOn}
                liczbaNot={tablicaObiektow?.length}
            />
          </div>
        )
        
      });
      setDockList(wygenerowanyKodHTML);
      resolve();
    })
  }

    
  const handleHide = () => {
    setActiveDock(0);
    setShowAll(false);
  }

  const onClick = () => {
    setLoaderOn(true);
    setShowAll(true);
    generateDocks()
      .then(()=>{
        return delay(5000)
      })
      .then(()=>{
        console.log('b')
        handleGeneratePDF33();
        setLoaderOn(false);
      })
      .catch( error => {
        console.log('Error: ', error)
      });
  }

  return (
    <div>
      {/* Dynamicznie generowane komponenty na podstawie tablicy obiektów */}
      <button
        onClick={onClick}
      >
        Wygeneruj pdf-y
      </button>
      <button
        // onClick={handleGenerateAndSendAllPdfs}
        disabled
        // onClick={handleGeneratePDF3}
      >
          Wyślij noty e-mailem
      </button>
      <div 
        id="docks"
        
      />
        {docksList}


      
    </div>
  );
};

export default NotyMasowa;