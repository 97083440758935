import React, { useEffect, useState } from "react";

import NotaNadawca from "./NotaNadawca";
import NotaTytul from "./NotaTytul";
import NotaAdresat from "./NotaAdresat";
import NotaDaty from "./NotaDaty";
import NotaTHead from "./NotaTHead";
import NotaTBody from "./NotaTBody";
import NotaSlownie from "./NotaSlownie";

const NotaA4 = props => {

    const { componentRef, wspolnota, mieszkaniec, tablicaCzynsze, udzialGrupaNota, lokale, datyNoty, setWysylkaStan, nota, notaIndex, setLoaderOn, liczbaNot } = props;
  
    const [kwota, setKwota] = useState(0);
    
    useEffect( () => {
        
        if (kwota > 0 && notaIndex >= 0) {
            setWysylkaStan( 
                prevState => ({
                  ...prevState,   // Zachowanie poprzedniego stanu
                  [`stan_${nota}`]: ""        // Dodanie nowego klucza 'wiek' z wartością 20
                })
            )
            if ((notaIndex + 1) == liczbaNot)
                setLoaderOn(false)
        }

    },[kwota, notaIndex, liczbaNot])

    return (
        <div    
            style={{
                width: '21cm',
                height: '29.7cm',
                backgroundColor: 'white',
                boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                padding: '15mm 15mm',
                boxSizing: 'border-box',
                maxWidth: '100%',
                maxHeight: '100%',
            }} 
            ref={componentRef ? componentRef : null}
        >
            <NotaNadawca
                wspolnota={wspolnota}   
            />

            <NotaTytul
                mieszkaniec={mieszkaniec} 
                tablicaCzynsze={tablicaCzynsze} 
                udzialGrupaNota={udzialGrupaNota}
            />
                
            <NotaAdresat
                mieszkaniec={mieszkaniec}
            />
                
            <NotaDaty
                mieszkaniec={mieszkaniec}
                lokale={lokale}
                udzialGrupaNota={udzialGrupaNota}
                datyNoty={datyNoty}
            />

            <section
                style={{
                    margin: '10mm 0 0 0'
                }}
            >
                <table
                    style={{
                        fontSize: '13px',
                        borderCollapse: 'collapse',
                        width: '100%'
                    }}
                >
                    <NotaTHead/>
                    <NotaTBody
                        mieszkaniec={mieszkaniec} 
                        wspolnota={wspolnota}
                        lokale={lokale}
                        udzialGrupaNota={udzialGrupaNota}
                        setKwota={setKwota}
                    />
                </table>
            </section>
            <NotaSlownie
                kwota={kwota}
            />
        </div>


    )
};

export default NotaA4;