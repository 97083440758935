import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { motion } from 'framer-motion' 
import MieszkancyDaneGrupyLokale from './MieszkancyDaneGrupyLokale';
import MieszkancyDaneGrupyHistoriaZmian from './MieszkancyDaneGrupyHistoriaZmian';
import MieszkancyDaneGrupyHistoriaWplat from './MieszkancyDaneGrupyHistoriaWplat.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import { lokale_wersja_max } from "../../functions/grupy";
import { format } from 'date-fns';
import { findMieszkanieId } from "../../functions/ulice.js";
import { 
    fetchUlice, 
    fetchLokale, 
  } from '../../Redux/Actions.js'; 
import { hasKey } from "../../functions/tablice.js";
import { formatKonta } from "../../functions/utilities.js";
import NotaModal from "../NotyWydruk/NotaModal.js";

const MieszkancyDaneGrupy = props => {

    const { mieszkaniec, editGrupe, setEditGrupe, grupy_u, udzialyKontaAkt_u, udzialyKonta_u, przelewy, wspolnota, tablicaPrzelewy_u, editDataOd, setEditDataOd } = props;
    const ulice = useSelector( state => state?.ulice?.data);
    const lokale = useSelector( state => state?.lokale?.data);
    const dispatch = useDispatch();
    useEffect( ()=> {
        dispatch(fetchUlice()); 
        dispatch(fetchLokale()); 
    },[dispatch]);
    const [czyWydruk, setCzyWydruk] = useState(false);
    const [zmianyGrupyPokaz, setZmianyGrupyPokaz] = useState( {id: 0, show: false} );
    const [przelewyGrupyPokaz, setPrzelewyGrupyPokaz] = useState({id: 0, show: false});
    const [udzialGrupaNota, setUdzialGrupaNota] = useState([]);

    

    const [nota, setNota] = useState(0);

    useEffect( () => setUdzialGrupaNota( udzialyKontaAkt_u?.find( u => u?.id == nota) ), [nota, udzialyKontaAkt_u]);

    const mieszkaniaTab = [];

    const showPrint = () => setCzyWydruk( prevState => !prevState );

    const lokaleArr = grupy_u?.map( g => 
        {
            if (g.data_od !== null) {
                const mieszaknie_w_grupie = udzialyKontaAkt_u?.find( udzial => udzial.grupa_numer === g.grupa_numer);
                return ({
                    czy_mieszkanie_w_grupie: hasKey(mieszaknie_w_grupie,"czy_mieszkanie_w_grupie") ? mieszaknie_w_grupie["czy_mieszkanie_w_grupie"] : "",
                    grupa_numer: g.grupa_numer,
                    id: g.id,
                    id_lokal: g.id_lokal,
                    rodzaj: lokale?.find( lokal => lokal.id == g.id_lokal)?.rodzaj,
                    id_user: g.id_user,
                    imie_nazwisko: mieszkaniec?.imie_nazwisko,
                    id_wspolnoty: g.id_wspolnoty,
                    data_od: g.data_od,
                    data_do: g.data_do,
                    wersja: g.wersja,
                    udzial: lokale?.find( lokal => lokal.id == g.id_lokal)?.udzial
                })
            }
    })

    const lokalDane = id => lokale?.filter( lokal => lokal.id == id)[0];
    const ulicaDane = id => ulice?.filter( ulica => ulica.id == id)[0];

    const [udzialyKontaAkt_uF, setUdzialyKontaAkt_uF] = useState([]);

    const sortByGrupaNumer = (array) => array.sort((a, b) => a.grupa_numer - b.grupa_numer);

    useEffect( () => {
        setUdzialyKontaAkt_uF( sortByGrupaNumer(udzialyKontaAkt_u) )
    },[udzialyKontaAkt_u])

    const listaGrup = udzialyKontaAkt_uF?.map( (udzialGrupa, i) => {

        const czyMieszkanie = udzialGrupa?.czy_mieszkanie_w_grupie;
        
        const mieszkanieLista = [];
        
        if (czyMieszkanie) {
            const mieszkanieId = findMieszkanieId(udzialGrupa, grupy_u, lokale);
            const mieszkanie = lokaleArr?.find( lokal => lokal?.id_lokal == mieszkanieId );
            const lokal = lokalDane(mieszkanie?.id_lokal);
            const ulica = ulicaDane(lokal?.ulica);
            const klatka = lokal?.klatka !== null && lokal?.klatka !== undefined && lokal?.klatka?.length > 0 ?  ` (klatka ${lokal?.klatka})` : "";
            const metraz = lokalDane(mieszkanie?.id_lokal)?.metraz; 
            const udzial = lokalDane(mieszkanie?.id_lokal)?.udzial;
            
            mieszkanieLista.push({
                ulica,
                lokal: lokal?.numer,
                klatka,
                metraz,
                udzial,
                grupa_numer: mieszkanie?.grupa_numer,
                grupa_id: mieszkanie?.id,
                liczba_domownikow: udzialGrupa?.liczba_domownikow,
                wersja: mieszkanie?.wersja,
                data_od: mieszkanie?.data_od
            });
            mieszkaniaTab.push(mieszkanieLista[0])
        }

        const garaze =  lokale_wersja_max(lokaleArr?.filter( lokal => lokal?.rodzaj === "garaż"   && lokal.grupa_numer === udzialGrupa.grupa_numer));
        const komorki = lokale_wersja_max(lokaleArr?.filter( lokal => lokal?.rodzaj === "komórka" && lokal.grupa_numer === udzialGrupa.grupa_numer));
        const strychy = lokale_wersja_max(lokaleArr?.filter( lokal => lokal?.rodzaj === "strych"  && lokal.grupa_numer === udzialGrupa.grupa_numer));

        const listaTemp = obj => {
            return obj?.map( g => {
                const lokal = lokalDane(g.id_lokal);
                const ulica = ulicaDane(lokal.ulica);
                const klatka = lokal.klatka !== null ?  ` (klatka ${lokal.klatka})` : "";
                if (g.data_do === null) {
                    return (    
                        {
                            ulica,
                            lokal: lokal.numer,
                            klatka,
                            metraz: lokalDane(g.id_lokal).metraz,
                            udzial: lokal.udzial,
                            grupa_numer: g.grupa_numer,
                            grupa_id: g.id,
                            wersja: g.wersja,
                            data_do: g.data_do,
                            data_od: g.data_od
                        }
                    )
                }
            })
        }
 
        const garazeLista  = listaTemp(garaze)?.filter( element => element !== undefined); 
        const komorkiLista = listaTemp(komorki)?.filter( element => element !== undefined); 
        const strychyLista = listaTemp(strychy)?.filter( element => element !== undefined);

        const taGrupaUdzial = udzialyKonta_u?.filter( u => u.grupa_numer == udzialGrupa.grupa_numer );

        const tablicaPrzelewy_g = tablicaPrzelewy_u?.filter( t => t?.nr_grupyUdzial == udzialGrupa?.grupa_numer);

        const czyPusta = (czyMieszkanie || garazeLista?.length || komorkiLista?.length || strychyLista?.length) ? false : true;

        return (
            <motion.div key={i} className="grupa ramka-br-light"
                initial={{x:100, opacity: 0}}
                animate={{x:0, opacity: 1, transition: {delay: i * 0.2}}}
            >
                <div className="naglowek">
                    <h3>
                        {`Grupa numer ${udzialGrupa.grupa_numer} ${ czyPusta ? " (bez lokali)" : ""}`}
                    </h3>
                    {/* <h5 style={{"width": "50%", "textAlign": "right"}}>
                        {`(informacje aktualne od ${dataFormat(udzialGrupa.data_od)})`}
                    </h5> */}
                    <div className="buttons">
                        <div className="opcje">
                            <FontAwesomeIcon 
                                icon={faGear} 
                                size="lg" 
                                className="pointer" 
                                style={{"margin": "5px 10px"}}
                                onClick={()=>setEditGrupe(udzialGrupa.id)}
                            />
                        </div>
                        {
                            mieszkaniec?.czy_nota == 1 ? (
                                <FontAwesomeIcon 
                                    icon={faFileInvoiceDollar} 
                                    size="lg"
                                    className="pointer" 
                                    style={{"margin": "5px 10px"}}
                                    onClick={()=>{
                                        showPrint()
                                        setNota(udzialGrupa.id)
                                    }}
                                />
                            ) : ""
                        }
                    </div>
                </div>

                <ul className="lista udzialKonto">
                    <li>
                        <div>
                            Czynsz liczony od:
                        </div>
                        <div>
                            {`${format(udzialGrupa.data_od, 'yyyy-MM-dd')}`}
                        </div>
                    </li>
                    <li>
                        <div>
                            Id grupy:
                        </div>
                        <div>
                            {udzialGrupa.id}
                        </div>
                    </li>
                    <li>
                        <div>
                            Numer konta bankowego:
                        </div>
                        <div>
                            {formatKonta(udzialGrupa.konto_bankowe)}
                        </div>
                    </li>
                </ul>

                {
                    taGrupaUdzial?.length > 1 ? (
                        <MieszkancyDaneGrupyHistoriaZmian 
                            zmianyGrupyPokaz={zmianyGrupyPokaz}
                            setZmianyGrupyPokaz={setZmianyGrupyPokaz}
                            taGrupaUdzial={taGrupaUdzial}
                        />
                    ) : ""
                }

                <MieszkancyDaneGrupyHistoriaWplat
                    udzialGrupa={udzialGrupa}
                    przelewy={przelewy}
                    przelewyGrupyPokaz={przelewyGrupyPokaz}
                    setPrzelewyGrupyPokaz={setPrzelewyGrupyPokaz}
                    tablicaPrzelewy_g={tablicaPrzelewy_g && tablicaPrzelewy_g?.length > 0 ? tablicaPrzelewy_g[0] : []}
                />

                { czyMieszkanie && (
                    <MieszkancyDaneGrupyLokale 
                        tytul="Mieszkanie" 
                        obj={mieszkanieLista} 
                        editDataOd={editDataOd} 
                        setEditDataOd={setEditDataOd}
                    />
                ) }
                { garazeLista && garazeLista.length > 0 ? (
                    <MieszkancyDaneGrupyLokale 
                        tytul="Garaże" 
                        obj={garazeLista} 
                        editDataOd={editDataOd}
                        setEditDataOd={setEditDataOd}
                    />
                ) : ""}
                { komorkiLista && komorkiLista.length > 0 ? (
                    <MieszkancyDaneGrupyLokale 
                        tytul="Komórki" 
                        obj={komorkiLista} 
                        editDataOd={editDataOd}
                        setEditDataOd={setEditDataOd}
                    />
                ) : ""}
                { strychyLista && strychyLista.length > 0 ? (
                    <MieszkancyDaneGrupyLokale 
                        tytul="Strychy" 
                        obj={strychyLista} 
                        editDataOd={editDataOd}
                        setEditDataOd={setEditDataOd}
                    />
                ) : ""}
                    
            </motion.div>   
        )
    })  

    return (
        <div className="listaGrup">
            {
                listaGrup.length === 0 ? (
                    <motion.div className="grupa "
                        initial={{x:100, opacity: 0}}
                        animate={{x:0, opacity: 1, transition: {delay: 1 * 0.2}}}
                    >
                        <div className="naglowek">
                            <h4>
                                {`Brak przypisanych nieruchomości`}
                            </h4>
                        </div>
                    </motion.div>   
                ) : listaGrup
            }
            {
                czyWydruk ? (
                    <NotaModal
                        showPrint={showPrint}
                        mieszkaniec={mieszkaniec}
                        wspolnota={wspolnota}
                        lokale={lokale}
                        udzialGrupaNota={udzialGrupaNota}
                        nota={nota}
                    />
                ) : ""
            }

        </div>
    )
}

export default MieszkancyDaneGrupy;