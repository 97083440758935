import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { delay } from "framer-motion";

import { 
  fetchWspolnoty, 
  fetchMieszkancy, 
  fetchMieszkancyAll,
  fetchPrzelewy, 
  fetchLokale, 
  fetchUlice, 
  fetchStawki, 
  fetchStawkiInd,
  fetchStawkiJednorazowe,
  fetchStawkiJednorazoweInd,
  fetchGrupy, 
  fetchGrupyAkt,
  fetchWersje, 
  fetchUdzialyKonta,
  fetchUdzialyKontaAkt,
  fetchUsers,
  fetchPlanowaneZuzycie  
} from '../../../Redux/Actions.js'; 
import { motion } from 'framer-motion';
import { wstepy } from '../../../functions/motions.js';

import { NavLink } from "react-router-dom";

import GoBackBtn from "../../GoBackBtn.js";
import Loader from "../../Loader.js";

import WspolnotyListaTHead from "./WspolnotyListaTHead.js";
import WspolnotyListaTBody from "./WspolnotyListaTBody.js";

import { getTablicaCzynsze2, getSaldo_w, getTablicaPrzelewy2 } from "../../../functions/tablice.js";

const WspolnotyLista = () => {

    const dispatch = useDispatch();
    
    const wspolnoty = useSelector( state  => state?.wspolnoty?.data);
    const mieszkancy = useSelector( state => state?.mieszkancy?.data);
    const przelewy = useSelector( state => state?.przelewy?.data);
    const lokale = useSelector( state => state?.lokale?.data);
    const stawki = useSelector( state => state?.stawki?.data);
    const stawkiInd = useSelector( state => state?.stawkiInd?.data );
    const stawkiJednorazowe = useSelector( state => state?.stawkiJednorazowe?.data);
    const stawkiJednorazoweInd = useSelector( state => state?.stawkiJednorazoweInd?.data);
    const grupy = useSelector( state => state?.grupy?.data);
    const udzialyKonta = useSelector( state => state?.udzialyKonta?.data);
    const udzialyKontaAkt = useSelector( state => state?.udzialyKontaAkt?.data);
    const wersje = useSelector( state => state?.wersje?.data);
    const planowaneZuzycie = useSelector( state => state?.planowaneZuzycie?.data);

    const [tablicaCzynsze, setTablicaCzynsze] = useState([]);
    const [tablicaPrzelewy, setTablicaPrzelewy] = useState([]);
    const [saldo, setSaldo] = useState([]);

    const [loaderOn, setLoaderOn] = useState(true);

    useEffect( () => {
      const fetchData = async () => {
        if (tablicaCzynsze?.length > 0 && tablicaPrzelewy?.length > 0 && saldo?.length > 0 && wspolnoty?.length > 0 ) {
          await delay(1000); 
          setLoaderOn(false);
        }
      };
      fetchData();
    },[tablicaCzynsze, tablicaPrzelewy, saldo, wspolnoty])
  
    useEffect( ()=> {
      dispatch(fetchWspolnoty()); 
      dispatch(fetchMieszkancy()); 
      dispatch(fetchMieszkancyAll());
      dispatch(fetchUsers());
      dispatch(fetchPrzelewy()); 
      dispatch(fetchLokale()); 
      dispatch(fetchUlice()); 
      dispatch(fetchStawki()); 
      dispatch(fetchStawkiInd());
      dispatch(fetchStawkiJednorazowe());
      dispatch(fetchStawkiJednorazoweInd());
      dispatch(fetchGrupy()); 
      dispatch(fetchUdzialyKonta());
      dispatch(fetchUdzialyKontaAkt());
      dispatch(fetchPlanowaneZuzycie());
    },[dispatch]);

    useEffect( () => dispatch(fetchWersje(grupy)), [dispatch, grupy]);

    useEffect( () => dispatch(fetchGrupyAkt(grupy, wersje)), [dispatch, grupy, wersje])

    useEffect( () => {
      setTablicaCzynsze(getTablicaCzynsze2(wspolnoty, mieszkancy, grupy, [],udzialyKonta, udzialyKontaAkt, lokale, stawki, planowaneZuzycie, stawkiJednorazowe, stawkiInd, stawkiJednorazoweInd /*, zaliczki */))
    },[wspolnoty, mieszkancy, grupy, udzialyKonta, lokale, stawki, planowaneZuzycie, stawkiJednorazowe, stawkiInd, stawkiJednorazoweInd /*, zaliczki */])

    useEffect( () => setTablicaPrzelewy( getTablicaPrzelewy2(wspolnoty, udzialyKontaAkt, przelewy)[0] ), [wspolnoty, udzialyKonta, przelewy])

    useEffect( () => {
      if (tablicaPrzelewy && tablicaPrzelewy){
        setSaldo( getSaldo_w(tablicaPrzelewy, tablicaCzynsze, wspolnoty) );
      }
    },[tablicaPrzelewy, tablicaCzynsze, wspolnoty]);

    // console.log(tablicaCzynsze[1]?.grupy?.filter( g => g?.id_user == 70))

    return (
        <motion.div 
          className="content"
          variants={wstepy}
          initial="initial"
          animate="animate"
        >
          <Loader stan={loaderOn} />
            <h1 className="nazwa">
                Lista wspólnot
            </h1>
            <div className="buttons">
              <GoBackBtn />
              <NavLink to="/wspolnoty/dodaj">
                  <button>Dodaj wspólnotę</button>
              </NavLink>
            </div>
            
            <ul className="lista wspolnoty">
              <WspolnotyListaTHead />
              { 
                <WspolnotyListaTBody 
                  wspolnoty={wspolnoty}
                  saldo={saldo}
                  tablicaPrzelewy={tablicaPrzelewy}
                  tablicaCzynsze={tablicaCzynsze}
                /> 
              }
            </ul>
        </motion.div>
    )
}

export default WspolnotyLista;

