import React, { useState } from 'react';

import MieszkancyDaneEditForm from './MieszkancyDaneEditForm.js';

import { motion } from 'framer-motion';
import { wstepy } from '../../functions/motions';

import MieszkancyDaneInformacje from './MieszkancyDaneInformacje.js';

const MieszkancyDane = (props) => {

    const { mieszkaniec, mieszkaniecAll, id_wspolnoty, add, edit, setEdit, udzialyKonta_w, udzialyKontaAkt_w, wspolnota, tablicaPrzelewy_u, editDataOd, setEditDataOd } = props;
    
    const [edytujGrupe, setEdytujGrupe] = useState(false);
    const [dodajGrupe, setDodajGrupe]=useState(false);

    const page = () => {
        if ( !add && !edit ) {
            return (
                <MieszkancyDaneInformacje 
                    mieszkaniec={mieszkaniec}
                    mieszkaniecAll={mieszkaniecAll}
                    id_wspolnoty={id_wspolnoty}
                    edytujGrupe={edytujGrupe}
                    setEdytujGrupe={setEdytujGrupe}
                    dodajGrupe={dodajGrupe}
                    setDodajGrupe={setDodajGrupe}
                    udzialyKonta_w={udzialyKonta_w}
                    udzialyKontaAkt_w={udzialyKontaAkt_w}
                    wspolnota={wspolnota}
                    tablicaPrzelewy_u={tablicaPrzelewy_u}
                    editDataOd={editDataOd}
                    setEditDataOd={setEditDataOd}
                />
            );
        } else if ( !add && edit ) {
            return (
                <MieszkancyDaneEditForm 
                    mieszkaniec={mieszkaniec}
                    id_wspolnoty={id_wspolnoty}
                    setEdit={setEdit}
                />  
            );
        } 
    }

    const handleDodajGrupe = () => {
        setEdit(false);
        setDodajGrupe(true);
    }
    const handleMieszkaniecEdit = () => setEdit(true);

    return(
        <motion.div 
            className="content mieszkancyDane"
            variants={wstepy}
            initial="initial"
            animate="animate"
        >
            <div className='button'>
                <button 
                    onClick={handleMieszkaniecEdit}
                >
                    Edytuj dane mieszkańca
                </button>
                <button onClick={handleDodajGrupe}>
                    Dodaj grupę czynszową
                </button>
                {/* <button 
                    onClick={handleWoda}
                >
                    Dodaj planowane zużycie wody
                </button> */}
            </div>
            { page() }
        </motion.div>
    )
}

export default MieszkancyDane;