import React, { useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { obliczDatyNoty } from '../../functions/dataFormat';
import { findMieszkanieId, adresPro } from '../../functions/ulice';

const NotyTBody = ( props ) => {

    const { grupy, lokale, ulice, wysylkaStan, tablica, setActiveDock } = props;

    const pobierz = async fileName => {
        try {
            const response = await axios.post( 
                '/api/noty/pobierz',
                { fileName: fileName }, 
                { responseType: 'blob' })
            
            if (response.statusText !== "OK") {
              throw new Error('Błąd podczas pobierania pliku PDF');
            }
      
            const url = window.URL.createObjectURL(new Blob([response.data]));
      
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName); // Nazwa pliku do pobrania
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
          } catch (error) {
            alert("Plik nie istnieje.")
          }
    }   

    const lista = tablica?.map ( (element, i) => {

        const mieszkanieId = findMieszkanieId(element.udzialyKontaAkt_u, grupy, lokale);
        const mieszkanie = adresPro(mieszkanieId, lokale, ulice);
        const stan = wysylkaStan[`stan_${element.nota}`] 
        const msc = obliczDatyNoty()?.miesiacNaliczenia;
        const fileName = `Nota obciążeniowa - ${ element?.mieszkaniec?.imie_nazwisko } - ${element?.nota} - ${ msc }.pdf`
        
        return (
            <tr key={i}>
                <td>
                    {`${element.mieszkaniec?.imie_nazwisko}` }
                </td>
                <td>
                    {`${mieszkanie} (grupa ${element.udzialyKontaAkt_u.grupa_numer})`}
                </td>
                <td
                    id={element?.nota}
                    onClick={()=>pobierz(fileName)}
                    className='pointer central'
                >
                    { <FontAwesomeIcon 
                        icon={faEye} 
                        size='xs'
                        id={element?.nota}
                    /> 
                    }
                </td>
                <td>
                    { stan }
                </td>
            </tr>
        )
    })

    return (
        <tbody>
            {lista}
        </tbody>
    )
}

export default NotyTBody;