import { createStore, applyMiddleware, combineReducers } from 'redux';
import { thunk } from 'redux-thunk';
import refreshReducer from './refreshReducer';
import wspolnotyReducer from './wspolnotyReducer';
import mieszkancyReducer from './mieszkancyReducer';
import mieszkancyAllReducer from './mieszkancyAllReducer';
import usersReducer from './usersReducer';
import przelewyReducer from './przelewyReducer';
import lokaleReducer from './lokaleReducer';
import uliceReducer from './uliceReducer';
import odczytyReducer from './odczytyReducer';
import stawkiReducer from './stawkiReducer';
import stawkiIndReducer from './stawkiIndReducer';
import stawkiJednorazoweReducer from './stawkiJednorazoweReducer';
import stawkiJednorazoweIndReducer from './stawkiJednorazoweIndReducer';
import udzialyKontaReducer from './udzialyKontaReducer';
import udzialyKontaAktReducer from './udzialyKontaAktReducer';
import udzialyKontaAllReducer from './udzialyKontaAllReducer';
import grupyReducer from './grupyReducer';
import grupyAllReducer from './grupyAllReducer';
import grupyAktReducer from './grupyAktReducer';
import wersjeReducer from './wersjeReducer';
import tablicaPrzelewyReducer from './TablicaCzynszeReducer';
import tablicaPrzelewyPelneReducer from './TablicaPrzelewyPelneReducer';
import tablicaCzynszeReducer from './TablicaCzynszeReducer';
import saldoReducer from './saldoReducer';
import planowaneZuzycieReducer from './planowaneZuzycieReducer';
import loginUserReducer from './loginUserReducerjs';
import userPasswordSendReducer from './userPasswordSendReducer';
import userReducer from './userReducer';
import logiReducer from './logiReducer';
import notyReducer from './notyReducer';

import credReducer from './credReducer';

const rootReducer = combineReducers({
  refresh: refreshReducer,
  wspolnoty: wspolnotyReducer,
  mieszkancy: mieszkancyReducer,
  users: usersReducer,
  przelewy: przelewyReducer,
  lokale: lokaleReducer,
  ulice: uliceReducer,
  odczyty: odczytyReducer,
  stawki: stawkiReducer,
  stawkiInd: stawkiIndReducer,
  stawkiJednorazowe: stawkiJednorazoweReducer,
  stawkiJednorazoweInd: stawkiJednorazoweIndReducer,
  grupy: grupyReducer,
  grupyAll: grupyAllReducer,
  grupyAkt: grupyAktReducer,
  udzialyKonta: udzialyKontaReducer,
  udzialyKontaAkt: udzialyKontaAktReducer,
  udzialyKontaAll: udzialyKontaAllReducer,
  wersje: wersjeReducer,
  tablicaPrzelewy: tablicaPrzelewyReducer,
  tablicaPrzelewyPelne: tablicaPrzelewyPelneReducer,
  tablicaCzynsze: tablicaCzynszeReducer,
  saldo: saldoReducer,
  creds: credReducer,
  mieszkancyAll: mieszkancyAllReducer,
  planowaneZuzycie: planowaneZuzycieReducer,
  loginUser: loginUserReducer,
  userPasswordSend: userPasswordSendReducer,
  user: userReducer,
  logi: logiReducer,
  noty: notyReducer
});

const store = createStore(
  rootReducer, 
  applyMiddleware(thunk),
  // process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() 
);
  
export default store;

