import React, { useState, useEffect } from 'react';
import { zl } from '../../functions/utilities';

const MieszkaniecStawkiJednorazowe = props => {

    const { stawkiJednorazowe_msc } = props;

    const generateListItems2 = s => {

        const rows = [];
        
        if (s?.dod_1_czy == 1) {
            rows.push(
                <li key={1}> 
                    <div>
                        { s?.dod_1_opis }
                    </div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div>
                        { zl(s.dod_1_kwota) }
                    </div>
                </li>
            )
        }

        if (s?.dod_2_czy == 1) {
            rows.push(
                <li key={2}>
                    <div>
                        { s?.dod_2_opis }
                    </div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div>
                        { zl(s.dod_2_kwota) }
                    </div>
                </li>
            )
        }

        if (s?.dod_3_czy == 1) {
            rows.push(
                <li key={3}>
                    <div>
                        { s?.dod_3_opis }
                    </div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div>
                        { zl(s.dod_3_kwota) }
                    </div>
                    
                </li>
            )
        }
        return rows;
    }
    
    return (
        <>
            {generateListItems2(stawkiJednorazowe_msc)}
        </>
    )

}

export default MieszkaniecStawkiJednorazowe;