import React, { useState, useEffect } from "react";
import { useLocation, } from 'react-router-dom';
import { useSelector } from 'react-redux';
import WspolnotaRozliczenieSaldo from './WspolnotaRozliczenieSaldo'; 
import GoBackBtn from "../GoBackBtn";
import { dataFormatMsc, nextMonth } from '../../functions/dataFormat';
import { wstepy } from '../../functions/motions';
import { getSaldo_g, saldoWykrzyknik } from "../../functions/tablice";
import Loader from "../Loader";
import WspolnotaRozliczenieTHead from "./WspolnotaRozliczenieTHead";
import WspolnotaRozliczenieTBody from "./WspolnotaRozliczenieTBody";
import WspolnotyRozliczenieTFooter from "./WspolnotaRozliczenieTFooter";

const WspolnotaRozliczenie = () => {

    const queryParams = new URLSearchParams(window.location.search)
    const idw = queryParams.get("id");

    const location = useLocation();
    const { state } = location;

    const wspolnoty = useSelector( state  => state?.wspolnoty?.data);
    const mieszkancy = useSelector( state => state?.mieszkancy?.data);

    const lokale = useSelector( state => state?.lokale?.data);
    const ulice = useSelector( state => state?.ulice?.data);
    const grupy = useSelector( state => state?.grupy?.data);
    const udzialyKontaAkt = useSelector( state => state?.udzialyKontaAkt?.data);
    const przelewy = useSelector( state => state?.przelewy?.data);
    const stawki = useSelector( state => state?.stawki?.data);
    const stawkiJednorazowe = useSelector( state => state?.stawkiJednorazowe?.data);

    const przelewyWspolnota = state?.from?.tablicaPrzelewy;
    const czynszeWspolnota = state?.from?.tablicaCzynsze;

    const [wspolnota, setWspolnota] = useState([]);
    const [miesiac, setMiesiac] = useState(dataFormatMsc(new Date()));
    const [listaMsc, setListaMsc] = useState([]);

    const [stawki_w, setStawki_w] = useState([]);
    const [stawkiJednorazowe_w, setStawkiJednorazowe_w] = useState([]);
    const [czynszeMsc, setCzynszeMsc] = useState([]);
    const [przelewy_w, setPrzelewy_w] = useState([]);

    const [loaderOn, setLoaderOn] = useState(true);

    useEffect( () => {
      if (wspolnota && listaMsc && stawki_w && czynszeMsc && przelewy_w)
        setLoaderOn(false)
    },[wspolnota, listaMsc, stawki_w, czynszeMsc, przelewy_w, udzialyKontaAkt])

    useEffect( () => setStawki_w( stawki?.find( s => s.id_wspolnoty == idw )), [stawki])
    useEffect(() => setStawkiJednorazowe_w( stawkiJednorazowe?.find( s => s.id_wspolnoty == idw && s.msc == miesiac) ),[stawkiJednorazowe, miesiac]);
    useEffect(() => setPrzelewy_w( przelewy?.filter( p => p.id_wspolnoty == idw && p.operacja !== "prowizja") ), [przelewy])
    useEffect(()=> setWspolnota( wspolnoty?.filter( w => w.id == idw)[0] ), [wspolnoty, idw])

    const dodajMiesiac = d => {
      d.setMonth(d.getMonth() + 1);
      return d;
    }

    useEffect(()=> {
      if (stawki?.length > 0) {
        let minData = stawki[0].data_od
        for (let i = 1; i < stawki.length; i++) {
          if (stawki[i].data_od < minData) {
            minData = stawki[i].data_od;
          }
        }

        const dataObecna = new Date();
        const dataMiesiacPoObecnej = nextMonth(new Date(dataObecna));
        const tablicaMiesiecy = [];
        let aktualnaData = new Date(minData);
        while (aktualnaData <= dataMiesiacPoObecnej) {
          tablicaMiesiecy.push(dataFormatMsc(aktualnaData));
          aktualnaData = dodajMiesiac(aktualnaData);
      }

        setListaMsc(tablicaMiesiecy)
      }
    },[stawki]);

    const select = listaMsc?.map( (e,i) => (
      <option key={i} value={e}>
        {e} 
      </option>
    ));

    const onChangeHandle = (e) => setMiesiac(e.target.value);

    useEffect( () => { 
      const temp2  = [];
      czynszeWspolnota?.forEach( czynsz => {
        czynsz?.grupy_udzial_msc?.forEach( c => {
          const temp = c.temp_msc?.filter( t => t.msc == miesiac)
          if (temp && temp.length > 0) {
            temp2.push(
              {
                id_user: czynsz.id_user,
                grupa_numer: c.grupa_numer,
                czynsz: temp[0],
              }
            )
          }
        });
      });
        
      setCzynszeMsc(temp2)
    },[czynszeWspolnota, miesiac])

    return (
        <div 
          className="content wspolnotaRozliczenie"
          variants={wstepy}
          initial="initial"
          animate="animate"
        >
          <Loader stan={loaderOn} />
            <h1 className="nazwa">
              Należności wspólnoty 
            </h1>
            <h3>
              { wspolnota?.name }
            </h3>
            <GoBackBtn />

            <select onChange={onChangeHandle} value={miesiac}>
              <option value="0">
                Wybierz okres:
              </option>
              {select}
            </select>
            <div style={{"height": "20px"}}></div>
            <div className="table-container">
              <table className="lista tabela naleznosci">
                <WspolnotaRozliczenieTHead 
                  stawki_w={stawki_w}
                  stawkiJednorazowe_w={stawkiJednorazowe_w}
                  czynszeMsc={czynszeMsc}
                />
                <WspolnotaRozliczenieTBody
                  idw={idw}
                  wspolnota={wspolnota}
                  lokale={lokale}
                  ulice={ulice}
                  grupy={grupy}
                  udzialyKontaAkt={udzialyKontaAkt}
                  stawki_w={stawki_w}
                  stawkiJednorazowe_w={stawkiJednorazowe_w}
                  przelewyWspolnota={przelewyWspolnota}
                  czynszeWspolnota={czynszeWspolnota}
                  czynszeMsc={czynszeMsc}
                  mieszkancy={mieszkancy}
                />
                <WspolnotyRozliczenieTFooter
                  udzialyKontaAkt={udzialyKontaAkt}
                  stawki_w={stawki_w}
                  stawkiJednorazowe_w={stawkiJednorazowe_w}
                  czynszeMsc={czynszeMsc}
                />
              </table>
            </div>

            <div style={{"height": "50px"}}></div>

            <WspolnotaRozliczenieSaldo 
              listaMsc={listaMsc} 
              przelewy_w={przelewy_w}
              przelewyWspolnota={przelewyWspolnota} 
              mieszkancy={mieszkancy} 
              czynsze={czynszeWspolnota} 
              miesiac={miesiac}
              setMiesiac={setMiesiac}
            />

        </div>
    )
}

export default WspolnotaRozliczenie;